<template>
    <div class="topmenu">
      <div class="menu w">
        <img class="menuimg" src="../assets/zhenghanglogo.png" alt="">
        <div class="menuitem">
          <div class="itemlist">首页</div>
          <div class="itemlist">学生</div>
          <div class="itemlist">企业</div>
          <div class="itemlist">教师</div>
        </div>
        <div class="menurigth">
          <div class="hoverstyle">
            <img class="rightimg" src="../assets/menusearch.png" alt="">
            <input class="input" type="text">
            <img class="blue" src="../assets/bluesearch.png" alt="">
          </div>
          <img class="rightimg" src="../assets/menupeo.png" alt="">
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: ''
}
</script>

<style scoped lang="less">
@keyframes shadow{
  0%{
    width: 30px;
  }
  100%{
    width: 160px;
  }
}
.topmenu{
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 50;
    background: linear-gradient(180deg, rgba(0, 77, 155, 0.5) 0%, rgba(0, 132, 203, 0) 100%);
    height: 139px;
    .dynamic{
      animation-name:top;/*动画的名字*/
      animation-duration: 1.5s;/*动画持续*/
      animation-delay: 1.5s;
      animation-fill-mode: backwards;/*规定动画结束后状态，保持 forwards 回到起始 backwards*/
    }
    .menu{
      height: 70px;
      padding: 24px 0 0 0;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .menuimg{
        width: 450px;
        height: 55px;
      }
      .menuitem{
        display: flex;
        font-size: 18px;
        color: #fff;
        font-weight: bold;
        .itemlist{
          width: 104px;
          height: 54px;
          line-height: 54px;
          text-align: center;
          &:hover{
            color: #004D9B;
            background-image: url(../assets/xiangyunbg.png);
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-color: rgba(255, 255, 255, 0.8);
            cursor: pointer;
          }
        }
      }
      .menurigth{
        display: flex;
        align-items: center;

        .rightimg{
          width: 34px;
          height: 34px;
          margin-left: 16px;
          cursor: pointer;
        }
        .hoverstyle{
          position: relative;
          .blue{
          display: none;
          z-index: 50;
          width: 19px;
          height: 18px;
          position: absolute;
          right: 10px;
          top: 8px;
          cursor: pointer;
        }
        .input{
          display: none;
          outline-style: none;
          border: none;
          height: 34px;
          border-radius: 20px 20px 20px 20px;
          padding-left: 20px;
          box-sizing: border-box;
          position: absolute;
          right: 0px;
        }
          &:hover{
            .blue{
              display: inline-block;
            }
            .input{
              animation-name:shadow;/*动画的名字*/
              animation-duration: 1s;/*动画持续*/
              animation-fill-mode: forwards;/*规定动画结束后状态，保持 forwards 回到起始 backwards*/
              display: inline-block;
            }
          }
        }
      }
    }
  }
</style>
