import axios from 'axios'
// import store from '@/store'
import { Message } from 'element-ui'
// import URL from '../../public/config.js'
// import router from '@/router'
import { clientId, clientSecret, tokenName } from '@/api/setting.config'
import { Base64 } from 'js-base64'
// create an axios instance
const service = axios.create({
  // process是Node的全局变量
  // process.env获取当前用户的环境变量的信息
  // VUE_APP_BASE_API 配置项目的根域名
  // baseURL:是项目的基准地址
  baseURL: process.env.VUE_APP_API,
  // 完整的地址=基准地址baseurl+请求地址request
  timeout: 5000 // request timeout
  // 请求超时会被中断
})

// 请求拦截器
service.interceptors.request.use(config => {
  // 获取token
  var token = ''
  if (JSON.parse(localStorage.getItem('pro__Access-Token'))) {
    token = JSON.parse(localStorage.getItem('pro__Access-Token')).value
  } else {
    token = ''
  }

  // const token = localStorage.getItem('token')
  if (token) {
    config.headers[tokenName] = 'Bearer ' + token
    config.headers.Authorization = `Basic ${Base64.encode(`${clientId}:${clientSecret}`)}`
    config.headers['Mate-Auth'] = 'Bearer ' + token
    // if (tenantId) {
    //   config.headers['mate-tenant-id'] = tenantId
    // }
  } else {
    config.headers.Authorization = `Basic ${Base64.encode(`${clientId}:${clientSecret}`)}`
  }
  // i18n
  // if (enableI18n) {
  //   const appLanguage = store.getters.lang
  //   if (appLanguage) {
  //     config.headers['Accept-Language'] = appLanguage
  //   }
  // }
  return config
},
error => {
  return Promise.reject(error)
}
)
// 响应拦截器
service.interceptors.response.use(res => {
  if (res.data.success) {
    return res.data
  } else {
    Message({
      message: res.data.msg,
      type: 'error'
    })
    return Promise.reject(new Error(res.data.msg))
  }
},
async (error) => {
  console.log(error.response)
  // 如果后端返回的状态码等于10002 说明token生效
  if (error.response.data.code === 1010005) {
    // 调回登录页面即可
    localStorage.clear()
    // window.location.href = URL.URL + 'user/login'
    window.location.reload()

    return Message({
      message: '请求超时，请重新登录',
      type: 'error'
    })
  }
  // Message({
  //   message: error.message,
  //   type: 'error'
  // })
  return Promise.reject(error)
}
)

export { service as axios }
